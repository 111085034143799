import React from "react";

export const OperatingSystemsLinux = () => {

    return (
        <>
            Linux
        </>
    )
};
