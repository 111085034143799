import React from "react";

import {BrowserRouter as Router} from "react-router-dom";
import Container from 'react-bootstrap/Container'
import Navbar from 'react-bootstrap/Navbar'
import NavDropdown from 'react-bootstrap/NavDropdown'
import Nav from 'react-bootstrap/Nav'

import {authenticatedFetchGet} from "../../lib/Fetch";
import SwitchRoutes from "./SwitchRoutes";
import {UseToken} from "../../lib/UseToken";
import {Login} from "../Login/Login";

export const Navigation = () => {
    const [coursesAndExercises, setCoursesAndExercises] = React.useState([]);
    const {token, setToken} = UseToken();

    React.useEffect(() => {
        getCoursesAndExercises(token).then(coursesAndExercises => setCoursesAndExercises(coursesAndExercises));
    }, [token]);


    if (!token) {
        return <Login setToken={setToken}/>
    }


    const getCoursesAndExercises = (token) => authenticatedFetchGet(token, '/courses')
    const subCourse = (course, courseParts) => {
        if(course[courseParts] === undefined)
        {
            return
        }
        return (
            course[courseParts].map((coursePart) => (
                <NavDropdown.Item key={"navDropdown_" + course.course_id + coursePart.heading}
                                  href={"/" + course.link + "/" + coursePart.link}
                                  className={!coursePart.active && "disabled"}>
                    {coursePart.heading}
                </NavDropdown.Item>
            ))
        )
    }


    return (
        <Router key="router">
            <Navbar key="navbar" bg="light" expand="lg">
                <Container>
                    <Navbar.Brand href="/">checkconf</Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="me-auto">
                            {coursesAndExercises.map((course) => (
                                <NavDropdown title={course.label} id="collasible-nav-dropdown">
                                    {subCourse(course,"exercises")}
                                    {subCourse(course,"course_admins")}
                                </NavDropdown>
                            ))}
                        </Nav>
                        <Nav>
                            <Nav.Link href="/logout">Log Out</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>

            <SwitchRoutes coursesAndExercises={coursesAndExercises}/>

        </Router>
    );
}

export default Navigation;