import React from "react";

import {Route, Switch} from "react-router-dom";

import {Home} from "../Home"
import {CourseAdmin} from "../Course/CourseAdmin";
import {NetworkExercise} from "../Course/NetworkApplications/NetworkExercise/NetworkExercise";
import {NetworkSettings} from "../Course/NetworkApplications/Network/NetworkSettings";
import {Login} from "../Login/Login";
import {Logout} from "../Login/Logout";
import {OperatingSystemsLinux} from "../Course/OperatingSystems/OperatingSystemsLinux";
import {OperatingSystemsSchedulingTraining} from "../Course/OperatingSystems/OperatingSystemsSchedulingTraining";
import {OperatingSystemsScheduling} from "../Course/OperatingSystems/OperatingSystemsScheduling";
import {OperatingSystemsProgramming} from "../Course/OperatingSystems/OperatingSystemsProgramming";
import {UseToken} from "../../lib/UseToken";


export const SwitchRoutes = ({coursesAndExercises}) => {
    const {token, setToken} = UseToken();
    const components = {
        NetworkExercise: NetworkExercise,
        NetworkSettings: NetworkSettings,
        OperatingSystemsLinux: OperatingSystemsLinux,
        OperatingSystemsSchedulingTraining: OperatingSystemsSchedulingTraining,
        OperatingSystemsScheduling: OperatingSystemsScheduling,
        OperatingSystemsProgramming: OperatingSystemsProgramming
    };

    const exercisesRoute = coursesAndExercises.map((course) => {
        const {exercises} = course
            return (
                exercises.map((exercise) => {
                    const Component = components[exercise.component];
                    return (
                        <Route key={exercise.heading} exact path={"/" + course.link + "/" + exercise.link}>
                            <Component token={token} exerciseId={exercise.exercise_id}/>
                        </Route>
                    )
                })
            )
        }
    )


    const course_admins = coursesAndExercises.map((course) => {
            return (
                course.course_admins.map((course_admin) => {
                    return (
                        <Route key={course_admin.heading} exact path={"/" + course.link + "/" + course_admin.link}>
                            <CourseAdmin token={token}/>
                        </Route>
                    )
                })
            )
        }
    )

    return (
        <Switch>
            <Route key="home" exact path="/">
                <Home token={token}/>
            </Route>
            <Route key="home" exact path="/home">
                <Home token={token}/>
            </Route>
            <Route key="home" exact path="/index.html">
                <Home token={token}/>
            </Route>
            {exercisesRoute}
            {course_admins}
            <Route key="schedulingtraining" exact path={"/os/test/schedulingtraining"}>
                <OperatingSystemsSchedulingTraining />
            </Route>
            <Route key="scheduling" exact path={"/os/test/scheduling"}>
                <OperatingSystemsScheduling token={token}/>
            </Route>
            <Route key="logout" exact path="/logout">
                <Logout/>
            </Route>
            <Route key="login" exact path="/login">
                <Login setToken={setToken} />
            </Route>

        </Switch>)

}

export default SwitchRoutes;