import React from "react";

import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {Button} from "@material-ui/core";

import {fetchPost} from "../../../lib/Fetch";
import {Success} from "../../lib/Success"

const Field = {
    width: "30px",
    height: "30px",
    borderWidth: "2px",
    borderColor: "#aaaaaa",
    borderStyle: "solid"
}

const burstTime = [2, 2, 3, 4, 4, 7]
const shuffledBurstTime = burstTime.sort((a, b) => 0.5 - Math.random());

const arrivalTime = [0, 1, 2, 3, 4, 5]
const shuffledArrivalTime = arrivalTime.sort((a, b) => 0.5 - Math.random());


async function checkSchedulingTask(processes, solution, algorithm) {
    let result = false;
    const checkSolution = {
        "processes": processes,
        "test": solution
    }
    try {
        result = await fetchPost(`/os/check/${algorithm}`, checkSolution)
    } catch (error) {
        console.log(error)
        throw error;
    }
    return result
}

export const OperatingSystemsSchedulingTrainingTask = ({maxLength, algorithm, heading}) => {
    const processes = {
        "processesId": ["A", "B", "C", "D", "E", "F"],
        "processArrivalTime": shuffledArrivalTime,
        "processBurstTime": shuffledBurstTime
    }
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };


    const [success, setSuccess] = React.useState(null);

    const validateTableInput = (event) => {
        let inputValue = event.target.innerHTML;
        if (inputValue === 'x') {
            event.target.innerHTML = ''
            event.target.style.backgroundColor = "white"
        } else if (inputValue === '') {
            event.target.innerHTML = 'x'
            event.target.style.color = "green"
            event.target.style.backgroundColor = "green"
        }

    }

    const tableRowHeading = (length) => {
        let rows = [];
        rows.push(<th style={Field}/>);
        for (let i = 1; i <= length; i++) {
            rows.push(<th style={Field}>{i}</th>);
        }
        return (
            <thead>
            <tr>
                {rows}
            </tr>
            </thead>
        )
    }

    const tableRow = (processID, length) => {
        let rows = [];
        for (let i = 0; i < length; i++) {
            rows.push(<td style={Field} onClick={validateTableInput}/>);
        }
        return (
            <tbody>
            <tr className="trSolution">
                <td style={Field}>{processID}</td>
                {rows}
            </tr>
            </tbody>
        )
    }
    const tableExercise = (processes) => {
        let exercise = [];
        exercise.push(tableRowHeading(maxLength))

        for (let i = 0; i < processes.processesId.length; i++) {
            exercise.push(tableRow(processes.processesId[i], maxLength));
        }
        return (
            <>
                {exercise}
            </>
        )
    }


    const handleInputTable = () => {
        return Array.prototype.map.call(document.querySelectorAll("#table" + algorithm + " .trSolution"), function (tr) {
            return Array.prototype.map.call(tr.querySelectorAll('td'), function (td) {
                return td.innerHTML;
            });
        });
    }

    const sendSolution = async () => {
        const input = handleInputTable()
        setSuccess((await checkSchedulingTask(processes, input, algorithm)).success)
    }

    return (
        <>
            <Accordion expanded={expanded === 'panel_' + algorithm}
                       onChange={handleChange('panel_' + algorithm)}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon/>}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                >{heading}
                </AccordionSummary>
                <AccordionDetails>
                    <table>
                        <thead>
                        <tr>
                            <th>ProcessID:</th>
                            <th>ArrivalTime:</th>
                            <th>Burst Time:</th>
                        </tr>
                        </thead>
                        <tbody>
                        {processes.processesId.map((processesId, index) => {
                            return (
                                <tr>
                                    <td>{processes.processesId[index]}</td>
                                    <td>{processes.processArrivalTime[index]}</td>
                                    <td>{processes.processBurstTime[index]}</td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>
                </AccordionDetails>
            </Accordion>


            <p/>
            <table id={"table" + algorithm}
                   style={{"borderWidth": "2px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid'}}>
                {tableExercise(processes)}
            </table>

            <br/>
            <Button variant="contained" color="primary" onClick={sendSolution}>Check</Button>
            <Success success={success}/>
            <p/>

        </>
    )
};