import React from "react";

import {OperatingSystemsSchedulingTask} from "./OperatingSystemsSchedulingTask";
import {authenticatedFetchGet} from "../../../lib/Fetch";

async function getProcess({token}) {
    let result = false;
    try {
        result = await authenticatedFetchGet(token, `/courses/os/exercises/scheduling/processes`)
    } catch (error) {
        console.log(error)
        throw error;
    }
    return result
}


async function getSolution({token}, algorithm) {
    let result = false;
    try {
        result = await authenticatedFetchGet(token, `/courses/os/exercises/scheduling/processes/` + algorithm)
    } catch (error) {
        console.log(error)
        throw error;
    }
    return result
}


export const OperatingSystemsScheduling = ({token}) => {
    const [processes, setProcesses] = React.useState({
        "processesId": [],
        "processArrivalTime": [],
        "processBurstTime": []
    });

    const [fcfs, setFCFS] = React.useState("A,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,B,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,C,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,D,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,E,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,F,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,");
    const [sjf, setSJF] = React.useState("A,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,B,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,C,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,D,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,E,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,F,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,");
    const [rr, setRR] = React.useState("A,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,B,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,C,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,D,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,E,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,F,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,");
    const processesNumber = 6;
    const maxLength = 30;


    React.useEffect(() => {
        try {
            getProcess({token}).then(
                (result) => {
                    setProcesses(result)
                }
            );
            getSolution({token}, "fcfs").then(
                (result) => {
                    if (result) {
                        setFCFS(result)
                    }
                }
            );
            getSolution({token}, "sjf").then(
                (result) => {
                    if (result) {
                        setSJF(result)
                    }
                }
            );
            getSolution({token}, "rr").then(
                (result) => {
                    if (result) {
                        setRR(result)
                    }
                }
            );
        } catch (e) {
        }
    }, [token]);


    return (
        <>
            <OperatingSystemsSchedulingTask token={token} processesNumber={processesNumber} algorithm="fcfs"
                                            heading="First Come First Serve" maxLength={maxLength} processes={processes}
                                            solution={fcfs}/>
            <OperatingSystemsSchedulingTask token={token} processesNumber={processesNumber} algorithm="sjf"
                                            heading="Shortest Job first" maxLength={maxLength} processes={processes}
                                            solution={sjf}/>
            <OperatingSystemsSchedulingTask token={token} processesNumber={processesNumber} algorithm="rr"
                                            heading="Round Robin" maxLength={maxLength} processes={processes}
                                            solution={rr}/>
        </>
    )
};
