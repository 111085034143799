import React from "react";

import ListItem from '@material-ui/core/ListItem'

import {authenticatedFetchGet} from "../../../../lib/Fetch";

async function checkNetworkTask(exerciseId, task, token) {
    const {task_id} = task;
    let result = false;
    try {
        result = await authenticatedFetchGet(token, `/exercise/${exerciseId}/tasks/${task_id}`)
    } catch (error) {
        console.log(error)
        throw error;
    }
    return result
}

export const NetworkTask = ({exerciseId, token, task}) => {
    const [error, setError] = React.useState(null);
    const [taskCheck, setTaskCheck] = React.useState(null);
    const [reload, setReload] = React.useState(false);

    const {task_id, description, answer_true, answer_false} = task;

    const reRender = () => {
        setError(null);
        setTaskCheck(null);
        setReload(!reload);
    }

    React.useEffect(() => {
        try {
            checkNetworkTask(exerciseId, task, token).then(
                (result) => {
                    setTaskCheck(result)
                    setReload(false)
                },
                (error) => {
                    setError(true)
                    setReload(false)
                    console.error(error)
                }
            );
        } catch (e) {
        }
    }, [exerciseId, task, token, reload]);

    if (error) {
        return <div>Error: {error.message}</div>;
    } else if (taskCheck) {
        const {success, points} = taskCheck;
        return (
            <ListItem>
                <div
                    className={success ? "bg-success list-group-item list-group-item-action flex-column align-items-start" : "bg-danger list-group-item list-group-item-action flex-column align-items-start"}>
                    <div className="d-flex w-100 justify-content-between">
                        <h4 className="mb-1">Task {task_id}</h4>
                        <button type="button" onClick={reRender} className="btn btn-primary  float-end">Retest</button>
                    </div>
                    <p className="mb-1">{success ? answer_true : answer_false}
                        <p className='float-end'>{points} Points</p>
                    </p>
                </div>
            </ListItem>
        );
    } else {
        return (
            <ListItem>
                <div className="list-group-item list-group-item-action flex-column align-items-start bg-warning">
                    <div className="d-flex w-100 justify-content-between">
                        <h4 className="mb-1">Task {task_id}</h4>
                        <div className="spinner-border" role="status">
                            <span className="sr-only"> </span>
                        </div>
                        <button type="button" onClick={reRender} className="btn btn-primary  float-end">Retest</button>
                    </div>
                    <p className="mb-1">{description}</p>
                </div>
            </ListItem>
        );
    }

};