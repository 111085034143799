import React from 'react';
import jwt_decode from "jwt-decode";

export const UseToken = () => {
    const getToken = () => {
        const tokenString = localStorage.getItem('token');
        const userToken = JSON.parse(tokenString);
        if(userToken === null || isExpired(userToken.token) === true)
        {
            return userToken?.token
        }
        return userToken?.token
    };

    const isExpired = token => {
        let isExpired = false;
        const decodedToken=jwt_decode(token, {complete: true});
        const dateNow = new Date();
        const timestamp = Math.floor(dateNow.getTime() / 1000)
        if(decodedToken.exp < timestamp)
        {
            isExpired = true;
        }
        return isExpired;
    }

    const [token, setToken] = React.useState(getToken());

    const saveToken = userToken => {
        localStorage.setItem('token', JSON.stringify(userToken));
        setToken(userToken.token);
    };



    return {
        setToken: saveToken,
        token
    }
}