import React from "react";

import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {Button} from "@material-ui/core";

import {authenticatedFetchPut} from "../../../lib/Fetch";

const Field = {
    width: "30px",
    height: "30px",
    borderWidth: "2px",
    borderColor: "#aaaaaa",
    borderStyle: "solid"
}

const FieldGreen = {
    width: "30px",
    height: "30px",
    borderWidth: "2px",
    borderColor: "#aaaaaa",
    borderStyle: "solid",
    color: "green",
    backgroundColor: "green"
}


async function saveSolutionToBackend({token, input, algorithm}) {
    let result = false;
    try {
        result = await authenticatedFetchPut(token, `/courses/os/exercises/scheduling/processes/` + algorithm, input)
    } catch (error) {
        console.log(error)
        throw error;
    }
    return result
}

export const OperatingSystemsSchedulingTask = ({token, maxLength, algorithm, heading, processes, solution}) => {
    const [expanded, setExpanded] = React.useState(false);
    const nameArr = solution.split(',');

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };



    const validateTableInput = (event) => {
        let inputValue = event.target.innerHTML;
        if (inputValue === 'x') {
            event.target.innerHTML = ''
            event.target.style.backgroundColor = "white"
        } else if (inputValue === '') {
            event.target.innerHTML = 'x'
            event.target.style.color = "green"
            event.target.style.backgroundColor = "green"
        }

    }

    const tableRowHeading = (length) => {
        let rows = [];
        rows.push(<th style={Field}/>);
        for (let i = 1; i <= length; i++) {
            rows.push(<th style={Field}>{i}</th>);
        }
        return (
            <thead>
            <tr>
                {rows}
            </tr>
            </thead>
        )
    }

    const tableRow = (processID, length) => {
        let rows = [];
        nameArr.shift(1);
        for (let i = 0; i < length; i++) {
            if(nameArr.shift(1) === "x") {
                rows.push(<td style={FieldGreen} onClick={validateTableInput}>x</td>);
            } else{
                rows.push(<td style={Field} onClick={validateTableInput}/>);
            }
        }
        return (
            <tbody>
            <tr className="trSolution">
                <td style={Field}>{processID}</td>
                {rows}
            </tr>
            </tbody>
        )
    }
    const tableExercise = (processes) => {
        let exercise = [];
        exercise.push(tableRowHeading(maxLength))

        for (let i = 0; i < processes.processesId.length; i++) {
            exercise.push(tableRow(processes.processesId[i], maxLength));
        }
        return (
            <>
                {exercise}
            </>
        )
    }


    const handleInputTable = () => {
        return Array.prototype.map.call(document.querySelectorAll("#table" + algorithm + " .trSolution"), function (tr) {
            return Array.prototype.map.call(tr.querySelectorAll('td'), function (td) {
                return td.innerHTML;
            });
        });
    }


    const saveSolution = async () => {
        const input = handleInputTable()
        await saveSolutionToBackend({token, input, algorithm})
    }

    return (
        <>
            <Accordion expanded={expanded === 'panel_' + algorithm}
                       onChange={handleChange('panel_' + algorithm)}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon/>}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                >{heading}
                </AccordionSummary>
                <AccordionDetails>
                    <table>
                        <thead>
                        <tr>
                            <th>ProcessID:</th>
                            <th>ArrivalTime:</th>
                            <th>Burst Time:</th>
                        </tr>
                        </thead>
                        <tbody>
                        {processes.processesId.map((processesId, index) => {
                            return (
                                <tr>
                                    <td>{processes.processesId[index]}</td>
                                    <td>{processes.processArrivalTime[index]}</td>
                                    <td>{processes.processBurstTime[index]}</td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>
                </AccordionDetails>
            </Accordion>


            <p/>
            <table id={"table" + algorithm}
                   style={{"borderWidth": "2px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid'}}>
                {tableExercise(processes)}
            </table>

            <br/>
            <Button variant="contained" color="primary" onClick={saveSolution}>Save</Button>
            <p/>

        </>
    )
};