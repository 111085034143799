import React from "react";

import {ListItem} from "@material-ui/core";
import TextField from '@material-ui/core/TextField';

import {validateIpv4Address} from "../../../../../lib/Validation";


export const IPAddressField = ({label, ipAddress, id, onChange, readonly}) => {
    const [validateIp, setValidateIp] = React.useState("");
    const [errorValidateIp, setErrorValidateIp] = React.useState(false);

    return (
        <ListItem><TextField
            required
            error={errorValidateIp}
            id={id}
            label={label}
            defaultValue={ipAddress}
            onChange={onChange}
            InputProps={{
                readOnly: readonly
            }}
            onChangeCapture={e => {
                if (validateIpv4Address(e.target.value)) {
                    setValidateIp("")
                    setErrorValidateIp(false)
                } else {
                    setValidateIp("No valid IPv4 Address")
                    setErrorValidateIp(true)
                }
            }}
            helperText={validateIp}
        /></ListItem>
    )
};
