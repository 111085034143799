import React from 'react';
import ReactDOM from 'react-dom';
//import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.css';
import 'jquery';


ReactDOM.render(

    <App />,
    document.getElementById('root')
);

reportWebVitals();
