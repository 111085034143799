import jwt from "jwt-decode";

const baseUrl = process.env.REACT_APP_BACKEND_BASE_URL + "/users"



export const fetchGet = async (url) => {
    const response = await fetch(url, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    })
    if(response.status !== 401 && response.status !== 403) {
        return await response.json()
    }
    localStorage.clear()
    return await response.json()
};

export const fetchPost = async (url, body) => {
    const fetchUrl = baseUrl + url;

    const response = await fetch(fetchUrl, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
    })

    if(response.status !== 401 || response.status !== 403) {
        return await response.json()
    }
    localStorage.clear()
    return await response.json()
};

export const authenticatedFetchGet = async (token, url) => {
    const {tokenUser} = jwt(token)
    const {user_id} = tokenUser
    const fetchUrl = baseUrl + "/" + user_id + url

    const response = await fetch(fetchUrl, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'x-access-token': token
        }
    })

    if(response.status !== 401 && response.status !== 403) {
        return await response.json()
    }
    localStorage.clear()
    return await response.json()

};

export const authenticatedFetchPut = async (token, url, body) => {
    const {tokenUser} = jwt(token)
    const {user_id} = tokenUser
    const fetchUrl = baseUrl + "/" + user_id + url;

    const response = await fetch(fetchUrl, {
        method: 'PUT',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'x-access-token': token
        },
        body: JSON.stringify(body)
    })

    if(response.status !== 401 && response.status !== 403) {
        return await response.json()
    }
    localStorage.clear()
    return await response.json()
};