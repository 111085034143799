import React from "react"
import SentimentSatisfiedAltIcon from '@material-ui/icons/SentimentSatisfiedAlt';
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';

export const Success = ({success}) => {
    if (success === true) {
        return (
            <>
                solved <SentimentSatisfiedAltIcon />
            </>
        )
    }
    return (
        <>
            not solved <SentimentVeryDissatisfiedIcon />
        </>
    )
}