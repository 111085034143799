import React from "react";


import {DataSet, Network as VisNetwork} from "vis";

import {Host} from "./Host";
import {authenticatedFetchGet} from '../../../../lib/Fetch';
import cloudPNG from "../img/internetServer.png"
import switchPNG from "../img/switch.png"
import gatewayPNG from "../img/gatewayServer.png"
import webPNG from "../img/webServer.png"
import dnsPNG from "../img/dnsServer.png"
import mailPNG from "../img/mailServer.png"
import workstationPNG from "../img/clientWorkstation.png"
import {Link} from "@material-ui/core";


const getHosts = (token) => {
    return authenticatedFetchGet(token, '/courses/neta/hosts/')
};

export const Network = ({token}) => {
    const [hosts, setHosts] = React.useState(null);
    const [expanded, setExpanded] = React.useState("panel_gatewayServer");
    const visNetworkJsRef = React.useRef(null);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const visNetworkNodes = new DataSet([
        {
            id: 255,
            label: "Internet",
            image: cloudPNG,
            shape: "image",
            level: 1,
            fixed: true
        },
        {
            id: 0,
            label: "Switch",
            image: switchPNG,
            shape: "image",
            opacity: 0.7,
            level: 3,
            fixed: true
        },
        {
            id: 1,
            label: "gatewayServer",
            image: gatewayPNG,
            shape: "image",
            level: 2,
            fixed: true
        },
        {
            id: 100,
            label: "webServer",
            image: webPNG,
            shape: "image",
            level: 4
        },
        {
            id: 101,
            label: "dnsServer",
            image: dnsPNG,
            shape: "image",
            level: 4
        },
        {
            id: 102,
            label: "mailServer",
            image: mailPNG,
            shape: "image",
            level: 4
        },
        {
            id: 103,
            label: "clientWorkstation",
            image: workstationPNG,
            shape: "image",
            level: 4
        }
    ]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const visNetworkEdges = new DataSet([
        {from: 255, to: 1},
        {from: 1, to: 0, length: 500},
        {from: 0, to: 100, length: 500},
        {from: 0, to: 101, length: 200},
        {from: 0, to: 102, length: 200},
        {from: 0, to: 103, length: 500}
    ]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const visNetworkData = {
        nodes: visNetworkNodes,
        edges: visNetworkEdges
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const visNetworkOptions = {
        autoResize: true,
        height: '100%',
        width: '100%',
        layout: {
            hierarchical: {
                direction: "UD",
                sortMethod: "directed",
                enabled: true,
                nodeSpacing: 100
            }
        },
        nodes: {
            font: {
                size: 40 // Works when size is > 4
            }
        },
        interaction: {
            hover: true
        },
        physics: {
            enabled: true
        }
    };


    React.useEffect(() => {
        getHosts(token).then(hosts => setHosts(hosts));
    }, [token]);


    const handleChange = (panel) => (event, isExpanded) => {
        console.log('clicked nodes:' + panel);
        setExpanded(isExpanded ? panel : false);
    };

    React.useEffect(() => {
        if (visNetworkJsRef.current) {
//            new VisNetwork(visNetworkJsRef.current, visNetworkData, visNetworkOptions)
            const network = new VisNetwork(visNetworkJsRef.current, visNetworkData, visNetworkOptions)
            network.on('click', function (properties) {
                try {
                    const id = properties.nodes
                    const clickedNodes = visNetworkNodes.get(id);
                    const label = clickedNodes[0].label;
                    setExpanded('panel_' + label)
                } catch (e) {

                }
            });

        }
        // Use `network` here to configure events, etc
    }, [visNetworkJsRef, visNetworkNodes, visNetworkEdges, visNetworkData, visNetworkOptions]);


    if (hosts !== null) {
        return (
            <>
                {hosts.map((server) => (
                    <Host
                        token={token} key={server.hostname} host={server} expanded={expanded} setExpanded={setExpanded}
                        handleChange={handleChange}
                    />
                ))}

                <Link href="https://neta.fh-campuswien.ac.at/proxmox">
                    https://neta.fh-campuswien.ac.at/proxmox
                </Link>
                <div ref={visNetworkJsRef} style={{height: "500px"}}/>
            </>
        )
    } else {
        return (
            <></>
        )
    }
};