import React from "react";

import {ListItem} from "@material-ui/core";
import TextField from '@material-ui/core/TextField';

export const FqdnField = ({label, ipAddress, id, onChange, readonly}) => {
    return (
        <ListItem><TextField
            required
            id={id}
            label={label}
            defaultValue={ipAddress}
            onChange={onChange}
            InputProps={{
                readOnly: readonly
            }}
        /></ListItem>
    )
};
