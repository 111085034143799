import React from "react";

import Navigation from "./components/Navigation/Navigation";
import {Login} from "./components/Login/Login";
import {UseToken} from './lib/UseToken';

function App() {

    const {token, setToken} = UseToken();

    if (!token) {
        return <Login setToken={setToken}/>
    }

    return (
        <div className="container">
            <Navigation />
        </div>
    );
}

export default App;
