import React from "react";

import {OperatingSystemsSchedulingTrainingTask} from "./OperatingSystemsSchedulingTrainingTask";

export const OperatingSystemsSchedulingTraining = () => {
    const processesNumber = 6;
    const maxLength = 30;

    return (
        <>
            <OperatingSystemsSchedulingTrainingTask processesNumber={processesNumber} algorithm="fcfs" heading="First Come First Serve" maxLength={maxLength} />
            <OperatingSystemsSchedulingTrainingTask processesNumber={processesNumber} algorithm="sjf" heading="Shortest Job first" maxLength={maxLength} />
            <OperatingSystemsSchedulingTrainingTask processesNumber={processesNumber} algorithm="rr" heading="Round Robin" maxLength={maxLength} />
        </>
    )
};
