import React from "react";
import jwt from "jwt-decode";
import {Card, CardContent} from "@material-ui/core"


export const Home = ({token}) => {
    const {tokenUser} = jwt(token);
    const {firstname, lastname} = tokenUser


    return (
        <>
            <Card>
                <CardContent>
                    Hello {firstname} {lastname}!
                </CardContent>
            </Card>
        </>
    )
};