import React from "react";

import {ListItem} from "@material-ui/core";


export const SSHField = ({ip, addPort}) => {

    return (
        <>
            <ListItem>ssh csdc@neta.fh-campuswien.ac.at
                -p {parseInt((ip).slice((ip).lastIndexOf('.') + 1)) + addPort}</ListItem>
        </>
    )
};
