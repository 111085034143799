import React from "react";

import {makeStyles} from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {Button, List, ListItem} from "@material-ui/core";

import {IPAddressField} from "./Host/IPAddressField";
import {SSHField} from "./Host/SSHField";
import {validateIpv4Address} from "../../../../lib/Validation";
import {authenticatedFetchPut} from "../../../../lib/Fetch";
import {FqdnField} from "./Host/FqdnField";
import jwt from "jwt-decode";


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
}));

export const Host = ({token, host, expanded, setExpanded, handleChange}) => {
    const {tokenUser} = jwt(token);
    const classes = useStyles();

    const [internal_ip, setInternal_ip] = React.useState(host.internal_ip);
    const [gateway_ip, setGateway_ip] = React.useState(host.gateway_ip);
    const [ns1_ip, setNs1_ip] = React.useState(host.ns1_ip);
    const [ns2_ip, setNs2_ip] = React.useState(host.ns2_ip);
    const [fqdn, setFqdn] = React.useState(tokenUser.fqdn);


    const handleChangeServer = async () => {
        if (validateIpv4Address(internal_ip) && validateIpv4Address(gateway_ip) && validateIpv4Address(ns1_ip) && validateIpv4Address(ns2_ip)) {
            const server = {
                "hostname": host.hostname,
                "internal_ip": internal_ip,
                "gateway_ip": gateway_ip,
                "ns1_ip": ns1_ip,
                "ns2_ip": ns2_ip,
                "fqdn": fqdn
            }
            await authenticatedFetchPut(token, "/courses/neta/labs/hosts/" + host.host_id, server)
        }
    }

    return (
        <div className={classes.root}>
            <Accordion expanded={expanded === ('panel_' + host.hostname)}
                       onChange={handleChange('panel_' + host.hostname)}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon/>}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                >{host.hostname}
                </AccordionSummary>
                <AccordionDetails>
                    <List>
                        {host.hostname === "gatewayServer" ?
                            <>
                                <ListItem>extrenal FQDN: {fqdn}</ListItem>
                                <SSHField ip={host.external_ip} addPort={22000} token={token}/>
                                <IPAddressField
                                    id={"external_ip" + host.host_id}
                                    label="External IP"
                                    ipAddress={host.external_ip}
                                    readonly={host.external_readonly}
                                />
                            </>
                            :
                            <IPAddressField
                                id={"internal_ip" + host.host_id}
                                label="Internal IP"
                                ipAddress={host.internal_ip}
                                readonly={host.internal_readonly}
                                onChange={e => setInternal_ip(e.target.value)}
                            />
                        }
                        <IPAddressField
                            id={"gateway_ip" + host.host_id}
                            label="Gateway IP"
                            ipAddress={host.gateway_ip}
                            readonly={host.gateway_readonly}
                            onChange={e => setGateway_ip(e.target.value)}
                        />
                        <IPAddressField
                            id={"nameserver1_ip" + host.host_id}
                            label="Nameserver1 IP"
                            ipAddress={host.ns1_ip}
                            readonly={host.ns1_readonly}
                            onChange={e => setNs1_ip(e.target.value)}
                        />
                        <IPAddressField
                            id={"nameserver2_ip" + host.host_id}
                            label="Nameserver2 IP"
                            ipAddress={host.ns2_ip}
                            readonly={host.ns2_readonly}
                            onChange={e => setNs2_ip(e.target.value)}
                        />
                        {host.hostname === "gatewayServer" &&
                        <IPAddressField
                            id={"internal_ip" + host.host_id}
                            label="Internal IP"
                            ipAddress={host.internal_ip}
                            readonly={host.internal_readonly}
                            onChange={e => setInternal_ip(e.target.value)}
                        />
                        }
                        <FqdnField
                            id={"fqdn" + host.host_id}
                            label="FQDN"
                            ipAddress={host.fqdn}
                            readonly={host.fqdn_readonly}
                            onChange={e => setFqdn(e.target.value)}
                        />
                        <Button variant="contained" color="primary" onClick={handleChangeServer}>
                            Save changes
                        </Button>
                    </List>
                </AccordionDetails>
            </Accordion>
        </div>
    );
};
