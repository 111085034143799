import React from "react";

import List from '@material-ui/core/List'

import {NetworkTask} from "./NetworkTask";
import {authenticatedFetchGet} from "../../../../lib/Fetch";


const getExercises = (exerciseId, token) => authenticatedFetchGet(token, `/exercise/${exerciseId}`);
const getExercisesUserPoints = (exerciseId, token) => authenticatedFetchGet(token, `/exercise/${exerciseId}/points`);

export const NetworkExercise = ({token, exerciseId}) => {
    const [exercise, setExercise] = React.useState(null);
    const [userPoints, setUserPoints] = React.useState(null);
    const [reload, setReload] = React.useState(false);

    const reRender = () => {
        setReload(!reload);
    }


    React.useEffect(() => {
        getExercises(exerciseId, token).then(exercise => setExercise(exercise));
        getExercisesUserPoints(exerciseId, token).then(points => setUserPoints(points));
    }, [exerciseId, token, reload]);

    if (exercise !== null) {
        const { exercise_id, tasks, heading} = exercise;
        return (
            <section className="list-group">
                <h2>
                    {heading} ({userPoints} Points) <> </>
                    <button type="button" onClick={reRender} className="btn btn-primary">Save</button>
                </h2>
                <List>
                    <div className="accordion" id="accordionExample">
                        {tasks.map((task, index) => {
                            return (
                                <NetworkTask token={token} key={index} exerciseId={exercise_id} task={task}/>
                            )
                        })}
                    </div>
                </List>
            </section>
        )
    } else {
        return (
            <></>
        )
    }
};
