import React from 'react';


export const Logout = () =>  {
    const [redirect, setRedirect] = React.useState(false);

    React.useEffect(() => {
        localStorage.clear()
        setTimeout(() => {
            setRedirect(true)
            window.location.href = "/";
        }, 10)
    }, [redirect]);




    return (
        <div>
            Redirecting to "/" ...
        </div>
    )
}
